import * as React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Logo from '../../components/Logo';

const styles = (theme) => ({
  root: {
    width: '70%',
    paddingBottom: theme.spacing(7),
  },
  buttons: {
    position: 'absolute',
    zIndex: 1000,
    '& svg': {
      fontSize: 25
    }
  },
  prev: {
    left: -10,
  },
  next: {
    right: -10,
  },
});

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};

function LogoCarousel(props) {
  const { classes } = props;

  const CustomRightArrow = ({ onClick }) => {
    return (
      <IconButton
        size='small'
        onClick={() => onClick()}
        aria-label="next"
        className={clsx(classes.buttons, classes.next)}>
        <NavigateNextIcon style={{ color: 'white' }} />
      </IconButton>
    )
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <IconButton
        size='small'
        onClick={() => onClick()}
        aria-label="prev"
        className={clsx(classes.buttons, classes.prev)}>
        <NavigateBeforeIcon style={{ color: 'white' }} />
      </IconButton>
    )
  };

  return (
    <div className={classes.root}>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        itemClass="carousel-item-padding-40-px"
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        <Logo src='images/logos/java-logo.png' title='Java' />
        <Logo src='images/logos/python-logo.png' title='Python' />
        <Logo src='images/logos/php-logo.png' title='PHP' />
        <Logo src='images/logos/laravel-logo.png' title='Laravel' />
        <Logo src='images/logos/c-sharp-logo.png' title='C#' />
        <Logo src='images/logos/html-logo.png' title='HTML' />
        <Logo src='images/logos/css-logo.png' title='CSS' />
        <Logo src='images/logos/javascript-logo.png' title='JavaScript' />
        <Logo src='images/logos/react-logo.png' title='React' />
        <Logo src='images/logos/react-native-logo.png' title='React Native' />
        <Logo src='images/logos/ruby-logo.png' title='Ruby' />
        <Logo src='images/logos/ruby-on-rails-logo.png' title='Ruby on Rails' />
      </Carousel>
    </div>
  );
}

export default withStyles(styles)(LogoCarousel);
