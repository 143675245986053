
export const data = [
  {
    id: 'taisho',
    img: 'images/projects/taisho-la.png',
    webUrl: 'https://www.taisho-direct.jp/',
  },
  {
    id: 'zing',
    img: 'images/projects/zing-sm.png',
  },
  {
    id: 'pacman-ai',
    img: 'images/projects/pacman-ai-br.png',
    gitUrl: 'https://github.com/shogo54/pacman-multiagent',
  },
  {
    id: 'knox-class-scheduler',
    img: 'images/projects/knox-class-scheduler-la.png',
    gitUrl: 'https://github.com/shogo54/knox-class-scheduler',
  },
  {
    id: 'foggy-clock',
    img: 'images/projects/foggy-clock-sm.png',
  },
  {
    id: 'laravel-website',
    img: 'images/projects/laravel-website-la.png',
    gitUrl: 'https://github.com/shogo54/laravel-website',
  },
];