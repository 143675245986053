import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from './Typography';
import Fade from './Fade';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: 'auto',
    paddingTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  textBox: {
    padding: theme.spacing(4),
  },
  heading: {
    color: theme.palette.secondary.main,
    textTransform: 'none',
  },
  subheading: {
    marginBottom: theme.spacing(4),
  },
  buttons: {
    marginTop: theme.spacing(3),
  },
  button: {
    marginRight: 30,
  },
  imageBox: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'cente',
    alignItems: 'center',
  },
  image: {
    maxHeight: 400,
    height: 'auto',
    maxWidth: '100%',
    margin: 'auto',
  },
}));

export default function ProjectGridItem(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      className={classes.root}
      id={props.id}
    >
      <Grid item xs={12} md={6} container direction='column' className={classes.textBox}>
        <Fade bottom>
          <Grid item xs>
            <Typography variant='h5' gutterBottom >
              {props.category}
            </Typography>
            <Typography variant='h4' gutterBottom className={classes.heading}>
              {props.heading}
            </Typography>
            <Typography variant='h5' className={classes.subheading} color='textSecondary'>
              {props.tools}
            </Typography>
            <Typography variant='h5' align='justify'>
              {props.description}
            </Typography>
          </Grid>
          <Grid item xs className={classes.buttons}>
            {props.webUrl != null ?
              <Button href={props.webUrl} variant='outlined' className={classes.button}>
                {props.webButton}
              </Button>
              : null
            }
            {props.gitUrl != null ?
              <Button href={props.gitUrl} variant='outlined'>
                {props.gitButton}
              </Button>
              : null
            }
          </Grid>
        </Fade>
      </Grid>
      <Grid item xs={12} md={6} className={classes.imageBox}>
        <Fade left>
          <img src={props.img} alt={props.id} className={classes.image} />
        </Fade>
      </Grid>
    </Grid>
  );
}

ProjectGridItem.propTypes = {
  id: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  tools: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  webButton: PropTypes.string.isRequired,
  gitButton: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  webUrl: PropTypes.string.isRequired,
  gitUrl: PropTypes.string.isRequired,
}