import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { HashLink } from 'react-router-hash-link';
import Button from './Button';

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: 200,
  },
}));

export default function ButtonLink(props) {
  const classes  = useStyles();

  return (
    <Button
      color="secondary"
      size="large"
      variant="contained"
      className={classes.button}
      component={HashLink}
      smooth
      to={props.to}
    >
      {props.children}
    </Button>
  );
}

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
}