
export const data = [
  {
    imgSrc: '/images/icons/web-design-color.png',
    imgAlt: 'web-design',
  },
  {
    imgSrc: '/images/icons/computer-color.png',
    imgAlt: 'computer',
  },
  {
    imgSrc: '/images/icons/ai-color.png',
    imgAlt: 'ai',
  },
];