import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import parse from 'html-react-parser';
import Fade from './Fade';
import Typography from './Typography';

const useStyles = makeStyles((theme, props) => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 5),
    },
    '& a': {
      color: theme.palette.error.main,
    },
  },
  image: {
    height: props => (props.imgSize === 'large')? 75: 55,
  },
  heading: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    color: theme.palette.secondary.main,
    textTransform: props => (props.headingStyle === 'normal')? 'none': '',
  },
}));

function GridItem(props) {
  const classes = useStyles(props);

  return (
    <Grid item xs={12} md={4}>
      <div className={classes.item}>
        <Fade left delay={750 * props.index}>
          <img
            className={classes.image}
            src={props.imgSrc}
            alt={props.imgAlt}
          />
        </Fade>
        <Fade bottom delay={750 * props.index}>
          <Typography variant='h6' align='center' className={classes.heading}>
            {props.heading}
          </Typography>
          <Typography variant='h5' align='justify'>
            {parse(props.description)}
          </Typography>
        </Fade>
      </div>
    </Grid>
  );
}

GridItem.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  imgSize: PropTypes.oneOfType(['large']),
  headingStyle: PropTypes.oneOfType(['normal']),
}

export default GridItem;
