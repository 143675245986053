
export const data = [
  {
    id: 'taisho',
    url: 'https://www.taisho-direct.jp/',
    img: 'images/showcase/taisho.png',
    title: 'E-commerce',
    width: '40%',
  },
  {
    id: 'zing',
    url: '',
    img: 'images/showcase/zing.png',
    title: 'Mobile App',
    width: '17%',
  },
  {
    id: 'pacman-ai',
    url: 'https://github.com/shogo54/pacman-multiagent',
    img: 'images/showcase/pacman.png',
    title: 'Pacman AI',
    width: '43%',
  },
  {
    id: 'knox-class-scheduler',
    url: 'https://github.com/shogo54/knox-class-scheduler',
    img: 'images/showcase/knox-class-scheduler.png',
    title: 'Web App',
    width: '38%',
  },
  {
    id: 'foggy-clock',
    url: '',
    img: 'images/showcase/foggy-clock.png',
    title: 'Mobile Game',
    width: '38%',
  },
  {
    id: 'laravel-website',
    url: 'https://github.com/shogo54/laravel-website',
    img: 'images/showcase/laravel-website.png',
    title: 'Website',
    width: '24%',
  },
  // {
  //   img: 'https://images.unsplash.com/photo-1506941433945-99a2aa4bd50a?auto=format&fit=crop&w=400&q=80',
  //   title: 'Walking',
  //   width: '40%',
  // },
  // {
  //   img: 'https://images.unsplash.com/photo-1533727937480-da3a97967e95?auto=format&fit=crop&w=400&q=80',
  //   title: 'Fitness',
  //   width: '20%',
  // },
  // {
  //   img: 'https://images.unsplash.com/photo-1518136247453-74e7b5265980?auto=format&fit=crop&w=400&q=80',
  //   title: 'Reading',
  //   width: '40%',
  // },
];