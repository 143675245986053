import React from 'react';
import Contact from '../views/Home/Contact';
import Experience from '../views/Home/Experience';
import HomeTop from '../views/Home/HomeTop';
import ProjectHighlights from '../views/Home/ProjectHighlights';
import SkillSet from '../views/Home/SkillSet';
import ProductSmokingHero from '../containers/modules/views/ProductSmokingHero';

function Home() {
  return (
    <React.Fragment >
      <HomeTop />
      <SkillSet />
      <ProjectHighlights />
      <Experience />
      <Contact />
      {/* <ProductSmokingHero /> */}
    </React.Fragment>
  );
}

export default Home;