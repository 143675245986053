import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import HomeTopLayout from './HomeTopLayout';
import ButtonLink from '../../components/ButtonLink';
import Fade from '../../components/Fade';
import LogoCarousel from './LogoCarousel';
import Typography from '../../components/Typography';

const backgroundImage = 'images/my-face.jpg';

const styles = (theme) => ({
  anchor: {
    position: 'relative',
    display: 'block',
    height: 64,
    marginTop: -64,
    [theme.breakpoints.up('sm')]: {
      height: 70,
      marginTop: -70,
    },
  },
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'right',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  title: {
    '& strong': {
      color: theme.palette.secondary.main,
    },
  },
});

function HomeTop(props) {
  const { classes } = props;
  const { t } = useTranslation('home');

  return (
    <React.Fragment>
      <div className={classes.anchor} id='home' />
      <HomeTopLayout backgroundClassName={classes.background}>
        {/* Increase the network loading priority of the background image. */}
        <img
          style={{ display: 'none' }}
          src={backgroundImage}
          alt="increase priority"
        />
        <Fade left>
          <Typography
            style={{ textTransform: 'none' }}
            align="center"
            variant="h2"
            className={classes.title}
          >
            {parse(t('home:homeTop.title'))}
          </Typography>
        </Fade>
        <Fade right>
          <Typography
            align="center"
            variant="h5"
            className={classes.h5}
          >
            {t('home:homeTop.subtitle')}
          </Typography>
        </Fade>
        <LogoCarousel />
        <Fade bottom delay={1000}>
          <ButtonLink to='/#skills'>
            {t('home:homeTop.button')}
          </ButtonLink>
          <Typography variant="body2" className={classes.more}>
            {t('home:homeTop.text')}
          </Typography>
        </Fade>
      </HomeTopLayout>
    </React.Fragment>
  );
}

HomeTop.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeTop);