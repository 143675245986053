import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import MuiDrawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LanguageSetting from './LanguageSetting';
import List from '../../components/List';
import NavLink from '../../components/NavLink';

const styles = (theme) => ({
	drawer: {
		width: 250,
	},
	subheader: {
		textAlign: 'center',
    textTransform: 'uppercase',
	},
	accountLink: {
    textTransform: 'capitalize',
	},
});

function Drawer(props) {
	const { classes, links, profiles, className } = props;
  const { t } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);

	const toggleDrawer = open => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setIsOpen(open);
	};

	return (
		<div className={className}>
			<IconButton
				className={classes.menuButton}
				aria-label="open drawer"
				onClick={toggleDrawer(true)}
			>
				<MenuIcon />
			</IconButton>
			<MuiDrawer open={isOpen} onClose={toggleDrawer(false)}>
				<div
					role="presentation"
					className={classes.drawer}
					onClick={toggleDrawer(false)}
					onKeyDown={toggleDrawer(false)}
				>
					<List subheader={t('nav.subheaders.menu')}>
						{links.map(link => (
							<NavLink
								to={link.to}
								hash={link.hash}
								type='drawer'
								key={link.to}
							>
								<ListItem button>
									{link.text}
								</ListItem>
							</NavLink>
						))}
					</List>
					<Divider />
					<List subheader={t('nav.subheaders.account')}>
						{profiles.map(profile => (
							<ListItem 
								button
								component='a'
								href={profile.url}
								key={profile.label}
							>
								<ListItemIcon>
									{profile.img}
								</ListItemIcon>
								<ListItemText primary={profile.label} className={classes.accountLink} />
							</ListItem>
						))}
					</List>
					<Divider />
					<List	subheader={t('nav.subheaders.language')}>
						<LanguageSetting />
					</List>
				</div>
			</MuiDrawer>
		</div>
	);
}

Drawer.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Drawer);
