import React from 'react';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import ExperienceGridItem from '../../components/ExperienceGridItem';
import { data } from '../../data/experience';

export default function ExperienceList() {
  const { t } = useTranslation('experience');

  return (
    <Container>
      {data.map((item, i) => (
        <ExperienceGridItem
          id={item.id}
          category={t(`experience:items.${item.id}.period`)}
          heading={t(`experience:items.${item.id}.job-title`)}
          tools={t(`experience:items.${item.id}.company`)}
          description={t(`experience:items.${item.id}.description`)}
          img={item.img}
          divider={i !== data.length - 1 ? true : false}
        />
      ))}
    </Container>
  );
}