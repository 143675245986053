import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTranslation } from 'react-i18next';
import Fade from '../../components/Fade';
import HomeSection from '../../containers/HomeSection';
import Typography from '../../components/Typography';

const styles = (theme) => ({
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.box.main,
    padding: theme.spacing(8, 2),
  },
  cardContent: {
    maxWidth: 400,
  },
  heading: {
    textTransform: 'none',
  },
  icon: {
    maxWidth: '32px',
    maxHeight: '32px',
  },
  imagesWrapper: {
    position: 'relative',
  },
  imageDots: {
    position: 'absolute',
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: '100%',
    background: 'url(/static/onepirate/productCTAImageDots.png)',
  },
  image: {
    position: 'absolute',
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: 600,
  },
});

function Contact(props) {
  const { classes } = props;
  const { t } = useTranslation('home');

  return (
    <HomeSection
      id='contact'
      title={t('home:contact.title')}
    >
      <Grid container>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
            <div className={classes.cardContent}>
              <Typography variant="h2" component="h2" gutterBottom
                className={classes.heading}
              >
                {t('home:contact.heading')}
              </Typography>
              <Typography variant="h5" gutterBottom>
                {t('home:contact.subheading')}
              </Typography>
              <Fade bottom cascade>
                <List component="nav" aria-label="main mailbox folders">
                  <ListItem>
                    <ListItemIcon>
                      <img alt='linkedin' src='/images/icons/linkedin-logo.png' className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText >
                      <Button href="https://linkedin.com/in/shogo-akiyama/" variant='outlined'>
                        {t('home:contact.linkedin')}
                      </Button>
                      {/* <Typography variant="h5">
                        <Link color='inherit' href="https://linkedin.com/in/shogo-akiyama/">{t('home:contact.linkedin')}</Link>
                      </Typography> */}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img alt='upwork' src='/images/icons/upwork-logo.png' className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText >
                      <Button href="https://upwork.com/ag/shogo/" variant='outlined'>
                        {t('home:contact.upwork')}
                      </Button>
                      {/* <Typography variant="h5">
                        <Link color='inherit' href="https://upwork.com/ag/shogo/">{t('home:contact.upwork')}</Link>
                      </Typography> */}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img alt='gmail' src='/images/icons/gmail-logo.png' className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText >
                      <Button href={'mailto:shogo.a.0504@gmail.com'} variant='outlined'>
                        {t('home:contact.email')}
                      </Button>
                      {/* <Typography variant="h5">
                        <Link color='inherit' href={'mailto:shogo.a.0504@gmail.com'}>{t('home:contact.email')}</Link>
                      </Typography> */}
                    </ListItemText>
                  </ListItem>
                </List>
              </Fade>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          <Hidden smDown>
            <Fade left>
              <div className={classes.imageDots} />
              <img
                src="/images/laptop.jpg"
                alt="call to action"
                className={classes.image}
              />
            </Fade>
          </Hidden>
        </Grid>
      </Grid>
    </HomeSection>
  );
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);
