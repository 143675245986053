import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import LightSpeed from 'react-reveal/LightSpeed';
import HomeTopLayout from '../Home/HomeTopLayout';
import Fade from '../../components/Fade';
import Typography from '../../components/Typography';

const backgroundImage = 'images/backgrounds/tech-future.jpg';

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginTop: 120,
    marginBottom: 120,
    '& strong': {
      color: theme.palette.secondary.main,
    },
    color: theme.palette.secondary.main,
  },
  textBox: {
    width: '100%',
  },
  text1: {
    fontStyle: 'italic',
    textDecoration: 'underline',
    textTransform: 'none',
  }
});

function HomeTop(props) {
  const { classes } = props;
  const { t } = useTranslation('home');

  return (
    <React.Fragment>
      <HomeTopLayout backgroundClassName={classes.background}>
        {/* Increase the network loading priority of the background image. */}
        <img
          style={{ display: 'none' }}
          src={backgroundImage}
          alt="increase priority"
        />
        <LightSpeed  right delay={1000} duration={1500}>
          <div className={classes.textBox}>
            <Typography
              align="left"
              variant="h1"
              className={classes.text1}
            >
              Maintainability
          </Typography>
          </div>
        </LightSpeed >
        <Fade top>
          <Typography
            style={{ textTransform: 'none' }}
            align="center"
            variant="h2"
            className={classes.title}
          >
            My Programming Principles
          </Typography>
        </Fade>
        <LightSpeed  left delay={2000} duration={1500}>
          <div className={classes.textBox}>
            <Typography
              style={{ fontStyle: 'italic' }}
              align="right"
              variant="h1"
              className={classes.text1}
            >
              Extensibility
          </Typography>
          </div>
        </LightSpeed >
      </HomeTopLayout>
    </React.Fragment>
  );
}

HomeTop.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeTop);
