
export const data = [
  {
    id: "avaxia",
    img: '/images/logos/companies/coca-cola-japan-logo.png',
  },
  {
    id: "zing",
    img: '/images/logos/companies/zing-logo.png',
  },
  {
    id: "dma",
    img: '/images/logos/companies/taisho-pharmaceutical-logo.png',
  },
  {
    id: 'knox-research',
    img: '/images/logos/companies/knox-college-logo.png',
  }
];