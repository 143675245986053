import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  flag: {
    height: '1.5em',
    width: '1.5em',
    marginRight: theme.spacing(1),
  },
  form: {
    paddingLeft: theme.spacing(.5),
    paddingTop: theme.spacing(.5),
    '&:hover': {
      borderRadius: 4,
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    }
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      backgroundColor: 'transparent'
    },
    '& img': {
      verticalAlign: 'middle',
    }
  },
}))(InputBase);

export default function LanguageSetting() {
  const classes = useStyles();

  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const handleChange = (event) => {
    setLang(event.target.value);
  }

  return (
    <FormControl className={classes.form}>
      <Select
        labelId="customized-select-label"
        id="customized-select"
        value={lang}
        onChange={handleChange}
        input={<BootstrapInput />}
      >
        <MenuItem value='en'><img src='images/icons/united-states-flag.png' alt={'en'} className={classes.flag} />English</MenuItem>
        <MenuItem value='ja'><img src='images/icons/japan-flag.png' alt={'ja'} className={classes.flag} />日本語</MenuItem>
      </Select>
    </FormControl>
  );
}