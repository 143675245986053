import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Fade from './Fade';
import Typography from './Typography';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: 'auto',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  textBox: {
    padding: theme.spacing(4),
  },
  heading: {
    color: theme.palette.secondary.main,
    textTransform: 'none',
  },
  subheading: {
    marginBottom: theme.spacing(4),
  },
  imageBox: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'cente',
    alignItems: 'center',
  },
  image: {
    maxHeight: 400,
    height: 'auto',
    maxWidth: '100%',
    margin: 'auto',
  },
}));

export default function ExperienceGridItem(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        justify='center'
        alignItems='center'
        className={classes.root}
      >
        <Grid item xs={14} md={7} container direction='column' className={classes.textBox}>
          <Fade bottom>
            <Typography variant='h5' gutterBottom >
              {props.category}
            </Typography>
            <Typography variant='h4' gutterBottom className={classes.heading}>
              {props.heading}
            </Typography>
            <Typography variant='h5' className={classes.subheading} color='textSecondary'>
              {props.tools}
            </Typography>
            <Typography variant='h5' align='justify'>
              {props.description}
            </Typography>
          </Fade>
        </Grid>
        <Grid item xs={10} md={5} className={classes.imageBox}>
          <Fade left>
            <img src={props.img} alt={props.id} className={classes.image} />
          </Fade>
        </Grid>
      </Grid>
      {props.divider ? <Divider /> : null}
    </React.Fragment>
  );
}

ExperienceGridItem.propTypes = {
  id: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  tools: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  divider: PropTypes.bool.isRequired,
}