import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from './Tooltip';


const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

function Logo(props) {
  const classes = useStyles();

  return (
    <Tooltip title={props.title}>
      <Avatar src={props.src} alt={props.title} className={classes.avatar}/>
    </Tooltip>
  );
}

export default Logo;
