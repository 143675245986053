import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme, props) => ({
  tooltip: {
    fontSize: props=> (props.fontSize==='small')? 14: 16,
  },
}));

function MyTooltip(props) {
  const classes = useStyles(props);

  return (
    <Tooltip title={props.title} arrow classes={classes} {...props}>
      {props.children}
    </Tooltip>
  );
}

MyTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  fontSize: PropTypes.objectOf(['small', 'large']),
}

export default MyTooltip;
