import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import ButtonLink from '../components/ButtonLink';
import Fade from '../components/Fade';
import Typography from '../components/Typography';

const styles = (theme) => ({
  anchor: {
    position: 'relative',
    display: 'block',
    height: 64,
    marginTop: -64,
    [theme.breakpoints.up('sm')]: {
      height: 70,
      marginTop: -70,
    },
  },
  root: {
    maxWidth: 1280,
    margin: 'auto',
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

function HomeSection(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <div className={classes.anchor} id={props.id} />
      <section className={classes.root}>
        <Container className={classes.container}>
          <div className={classes.title}>
            <Fade top>
              <Typography
                variant='h4'
                marked='center'
                component='h2'
              >
                {props.title}
              </Typography>
            </Fade>
          </div>
          {props.children}
          {props.buttonText && props.buttonTo ?
            <div className={classes.button}>
              <Fade bottom>
                <ButtonLink to={props.buttonTo} >
                  {props.buttonText}
                </ButtonLink>
              </Fade>
            </div>
            : null
          }
        </Container>
        {props.divider ? <Divider /> : null}
      </section>
    </React.Fragment>
  );
}

HomeSection.prototype = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  divider: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonTo: PropTypes.string,
}

export default withStyles(styles)(HomeSection);