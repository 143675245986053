import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import HomeGridItem from '../../components/HomeGridItem';
import HomeSection from '../../containers/HomeSection';
import { data } from '../../data/skillset';

export default function SkillDisplay() {
  const { t } = useTranslation('home');

  return (
    <HomeSection
      id='skills'
      title={t('home:skillset.title')}
      divider
    >
      <Grid container spacing={4}>
        {data.map((item, i) => (
          <HomeGridItem
            imgSrc={item.imgSrc}
            imgAlt={item.imgAlt}
            heading={t(`home:skillset.content${i + 1}.heading`)}
            description={t(`home:skillset.content${i + 1}.description`)}
            index={i}
          />
        ))}
      </Grid>
    </HomeSection>
  );
}