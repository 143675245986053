import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
	subheader: {
		textAlign: 'center',
    textTransform: 'uppercase',
	},
}));

export default function MyList(props) {
	const classes = useStyles();

	return (
		<List
			subheader={
				<ListSubheader component="div" className={classes.subheader}>
					{props.subheader}
				</ListSubheader>
			}
		>
			{props.children}
		</List>
	);
}

MyList.propTypes = {
	subheader: PropTypes.string,
}