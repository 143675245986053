import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import HomeGridItem from '../../components/HomeGridItem';
import HomeSection from '../../containers/HomeSection';
import { data } from '../../data/homeExperience';

export default function Experience() {
  const { t } = useTranslation('home');

  return (
    <HomeSection
      id='experience'
      title={t('home:experience.title')}
      divider
      buttonText={t('home:experience.buttonText')}
      buttonTo='/experience'
    >
      <Grid container spacing={4}>
        {data.map((item, i) => (
          <HomeGridItem
            imgSrc={item.imgSrc}
            imgAlt={item.imgAlt}
            heading={t(`home:experience.content${i + 1}.heading`)}
            description={t(`home:experience.content${i + 1}.description`)}
            imgSize='large'
            headingStyle='normal'
            index={i}
          />
        ))}
      </Grid>
    </HomeSection>
  );
}