import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiLink from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import NavLink from 'react-router-dom/NavLink';
import { NavHashLink } from 'react-router-hash-link';
import Typography from './Typography';

const useStyles = makeStyles((theme, props) => ({
  link: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  title: {
    fontSize: 24,
    marginLeft: theme.spacing(6),
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
      textAlign: 'left',
    },
  },
  nav: {
    fontSize: 16,
    margin: theme.spacing(0, 2),
  },
  drawer: {
    fontSize: 16,
    textTransform: 'capitalize',
    fontWeight: 'normal',
  },
  active: {
    color: theme.palette.secondary.main,
  }
}));

export default function MyNavLink(props) {
  const classes = useStyles(props);

  return (
    <Typography
      variant="h6"
      className={classes[props.type]}
    >
      <MuiLink
        className={classes.link}
        underline="none"
        component={props.hash ? NavHashLink : NavLink}
        to={props.to}
        exact
        smooth={props.hash}
        activeClassName={props.type ==='title' || props.hash ? "" : classes.active}
      >
        {props.children}
      </MuiLink>
    </Typography >
  );
}

MyNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['nav', 'drawer', 'title']).isRequired,
  hash: PropTypes.bool,
}

MyNavLink.defaultProps = {
  type: 'nav',
  hash: false,
}