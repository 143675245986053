import React from 'react';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import ProjectGridItem from '../../components/ProjectGridItem';
import { data } from '../../data/projects';

export default function ProjectList() {
  const { t } = useTranslation('projects');

  return (
    <Container>
      {data.map((item, i) => (
        <React.Fragment>
          <ProjectGridItem
            id={item.id}
            category={t(`projects:items.${item.id}.category`)}
            heading={t(`projects:items.${item.id}.heading`)}
            tools={t(`projects:items.${item.id}.tools`)}
            description={t(`projects:items.${item.id}.description`)}
            webButton={t('projects:buttons.web')}
            gitButton={t('projects:buttons.git')}
            img={item.img}
            webUrl={item.webUrl}
            gitUrl={item.gitUrl}
          />
          {(i !== data.length - 1) ? <Divider /> : null}
        </React.Fragment>
      ))}
    </Container>
  );
}