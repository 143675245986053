import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Drawer from './Drawer';
import LanguageSetting from './LanguageSetting';
import Toolbar, { styles as toolbarStyles } from './Toolbar';
import NavLink from '../../components/NavLink';
import Tooltip from '../../components/Tooltip';

const styles = (theme) => ({
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'space-between',
  },
  left: {
    flex: 1,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  img: {
    maxHeight: '1em',
    maxWidth: '1em',
  },
  drawerImg: {
    height: '1.5em',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up("lg")]: {
      display: 'flex',
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up("lg")]: {
      display: 'none',
    }
  }
});

function NavBar(props) {
  const { classes } = props;
  const { t } = useTranslation();

  const links = [
    {
      text: t('nav.links.home'),
      to: '/#home',
    },
    {
      text: t('nav.links.skills'),
      to: '/#skills',
      hash: true,
    },
    {
      text: t('nav.links.projects'),
      to: '/projects'
    },
    {
      text: t('nav.links.experience'),
      to: '/experience'
    },
    // {
    //   text: 'About',
    //   to: '/about',
    // },
    {
      text: t('nav.links.contact'),
      to: '/#contact',
      hash: true,
    },
  ];

  const profiles = type => [
    {
      title: t('nav.tooltips.github'),
      label: 'github',
      url: 'https://github.com/shogo54/',
      img: (<GitHubIcon />)
    },
    {
      title: t('nav.tooltips.linkedin'),
      label: 'linkedin',
      url: 'https://linkedin.com/in/shogo-akiyama/',
      img: (<LinkedInIcon />)
    },
    {
      title: t('nav.tooltips.upwork'),
      label: 'upwork',
      url: 'https://upwork.com/ag/shogo/',
      img: (
        <img
          src={'/images/icons/upwork-logo-bw2.png'}
          alt='upwork'
          className={type === 'drawer' ? classes.drawerImg : classes.img}
        />)
    },
    {
      title: t('nav.tooltips.email'),
      label: 'email',
      url: 'mailto:shogo.a.0504@gmail.com',
      img: (<MailOutlineIcon />)
    },
  ]

  return (
    <div>
      <MuiAppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <div className={classes.left} >
            <NavLink to='/' type='title'>
              {'Shogo Tech'}
            </NavLink>
          </div>
          <div className={classes.sectionDesktop}>
            {links.map(link => (
              <NavLink to={link.to} hash={link.hash} key={link.to}>
                {link.text}
              </NavLink>
            ))}
          </div>
          <div className={clsx(classes.sectionDesktop, classes.right)}>
            {profiles().map(profile => (
              <Tooltip title={profile.title} fontSize='small' key={profile.label}>
                <IconButton aria-label={profile.label} href={profile.url}>
                  {profile.img}
                </IconButton>
              </Tooltip>
            ))}
            <LanguageSetting />
          </div>
          <Drawer className={classes.sectionMobile} links={links} profiles={profiles('drawer')} />
        </Toolbar>
      </MuiAppBar>
      <div className={classes.placeholder} />
    </div>
  );
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavBar);
