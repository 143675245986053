
export const data = [
  {
    imgSrc: '/images/logos/companies/coca-cola-japan-logo.png',
    imgAlt: 'coca cola',
  },
  {
    imgSrc: '/images/logos/companies/zing-logo.png',
    imgAlt: 'zing',
  },
  {
    imgSrc: '/images/logos/companies/taisho-pharmaceutical-logo.png',
    imgAlt: 'taisho pharmaceutical',
  },
];